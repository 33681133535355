import React, { Component } from 'react';
import { Header, Icon, List } from 'semantic-ui-react'


const Projects = () => {

  return (
    <div>
      <Header size='small'>
        Select Work
      </Header>
      <List>
        <List.Item>
          <Icon name='volleyball ball' size='large' verticalAlign='middle' />
          <List.Content>
            <a href='http://ilobby.thisjames.com' target='_blank'>iLobby</a>. Find and influence your Elected Officials.
            <List.List>
              <List.Item>
                <a href='https://www.loom.com/share/c9efd90ca7f742078f6b462430303634' target='_blank'>Demo</a> 
              </List.Item>
              <List.Item>
                <a href='https://trello.com/b/9C6jGF7k/ilobby' target='_blank'>Trello</a> 
              </List.Item>
              <List.Item>
                <a href='https://github.com/jasmosez/ilobby-frontend' target='_blank'>Github</a> 
              </List.Item>
            </List.List>
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name='chart bar' size='large' verticalAlign='middle' />
          <List.Content>
            <a href='https://github.com/jasmosez/z-mail-backend' target='_blank'>Z-Mail Github</a>. Visualize your Gmail in support of ‘Inbox Zero’ methodology.
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name='newspaper outline' size='large' verticalAlign='middle' />
          <List.Content>
            <a href='https://github.com/jasmosez/newsy-frontend' target='_blank'>Newsy Github</a>. Browse political journalists, their articles and social media feeds and rate their journalistic integrity.
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name='circle notch' size='large' verticalAlign='middle' />
          <List.Content>
            <a href='https://www.advocacy-institute.org' target='_blank'>The Advocacy Institute</a>. {"Legislative Trainings & Tools for Advocates."}
            <List.List>
              <List.Item>
                <a href='https://interactive.advocacy-institute.org' target='_blank'>Interactive Legislator Maps</a>
              </List.Item>
              <List.Item>
                <a href='https://tools.advocacy-institute.org/geographic/senate' target='_blank'>Geographic Maps</a>
              </List.Item>
            </List.List>
          </List.Content>
        </List.Item>

      </List>
    </div>
  );
}

export default Projects

// const mapStateToProps = (state) => {
//   return {
//     exampleMessage: state.exampleState.exampleMessage
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     changeExampleMessage: () => { dispatch(changeExampleMessage()) }
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Projects);
