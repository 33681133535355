import React, { Component } from 'react';
import { Header, Icon, List } from 'semantic-ui-react'


const Personas = () => {

  return (
    <div>
      <Header size='huge'>
        thisjames
      </Header>
      <List>
        <List.Item>
          <Icon name='linkedin' size='large' verticalAlign='middle' />
          <List.Content as='a' href='https://www.linkedin.com/in/jasschaffer/' target='_blank'>
            jasschaffer
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name='github' size='large' verticalAlign='middle' />
          <List.Content as='a' href='http://www.github.com/jasmosez' target='_blank'>
            jasmosez
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name='medium' size='large' verticalAlign='middle' />
          <List.Content as='a' href='http://www.medium.com/@jasmosez' target='_blank'>
            @jasmosez
          </List.Content>
        </List.Item>

      </List>
    </div>
  );
}

export default Personas

// const mapStateToProps = (state) => {
//   return {
//     exampleMessage: state.exampleState.exampleMessage
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     changeExampleMessage: () => { dispatch(changeExampleMessage()) }
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Personas);
