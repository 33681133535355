import React, { Component } from 'react';
import { Header, Icon, List } from 'semantic-ui-react'


const Summary = () => {

  return (
    <div>
      <Header size='small'>
        James Morris Schaffer
      </Header>
      <List>
        <List.Item>
          {'Founder, Strategist, Product Designer & Fullstack Software Engineer. Lover of data and infographic design.'}
        </List.Item>
        <List.Item>
          {'Over a decade of experience managing small organizations. Skilled in Entrepreneurship, Planning, Curriculum Design & Development, Data ETL and Financial Modeling. Committed to leveraging excellent product design and data engineering for social equity and progressive policy.'}
        </List.Item>
      </List>
    </div>
  );
}

export default Summary

// const mapStateToProps = (state) => {
//   return {
//     exampleMessage: state.exampleState.exampleMessage
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     changeExampleMessage: () => { dispatch(changeExampleMessage()) }
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Summary);
