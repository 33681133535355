import React, { Component } from 'react';
import logo from './logo.svg';
import logo2 from './logo2.svg';
import './App.css';
import Personas from './components/Personas';
import Summary from './components/Summary';
import Projects from './components/Projects';
import { Grid, Image } from 'semantic-ui-react'

// import { connect } from 'react-redux'
// import { changeExampleMessage } from './actions'

class App extends Component {

  // handleClick = () => {
  //   console.log('hello')
  //   this.props.changeExampleMessage()
  // }

  render() {
    return (
      <div className='App'>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width='16'>
             <Personas />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width='16'>
              <Projects />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width='10'>
              <Summary />
            </Grid.Column>
            <Grid.Column width='6'>
              <Image circular src='headshot2_jms.jpg' size='medium' />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </div>
    );
  }
}

export default App

// const mapStateToProps = (state) => {
//   return {
//     exampleMessage: state.exampleState.exampleMessage
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     changeExampleMessage: () => { dispatch(changeExampleMessage()) }
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(App);
